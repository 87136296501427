import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { WeatherDaily, WeatherHourly } from './components';
import { TabsSectionStyled, WeatherWrapper, Wrapper } from './styles';
import { EnergyCounter } from '../Parks/components/EnergyCounter';
import { Breadcrumbs } from 'components/common/Breadcrumbs';
import { ITab } from 'components/common/TabsSection/TabsSection';
import { WindTurbinesTable } from 'components/shared/WindTurbinesTable';
import routePaths from 'constants/routePaths';
import { useParkContext } from 'contexts/park';
import { convertWh } from 'utils/functions/converters';

export const ParkComponent: FC = () => {
  const { t } = useTranslation();
  const { details, weather } = useParkContext();
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('tablet'),
  );

  const windTurbineCounterText = useMemo(() => {
    if (details?.turbineCount === 1) {
      return `1 ${t('pages.parkDetails.windTurbinesCounter.singular')}`;
    }

    return `${details?.turbineCount || 0} ${t(
      'pages.parkDetails.windTurbinesCounter.plural',
    )}`;
  }, [details?.turbineCount, t]);

  const weatherTabs = useMemo(
    (): ITab[] => [
      {
        label: (
          <Typography variant="bodyMStrong">
            {t('pages.parkDetails.weather.todayWeatherTitle')}
          </Typography>
        ),
        value: <WeatherHourly />,
        key: 'today',
      },
      {
        label: (
          <Typography variant="bodyMStrong">
            {t('pages.parkDetails.weather.dailyWeatherTitle')}
          </Typography>
        ),
        value: <WeatherDaily />,
        key: 'daily',
      },
    ],
    [t],
  );

  const activeEnergy = useMemo(
    () => convertWh(details?.totalActiveEnergy || 0, t),
    [details, t],
  );

  return (
    <Wrapper>
      <Breadcrumbs
        items={[
          {
            label: t('pages.parks.title'),
            path: routePaths.parks.root,
          },
          { label: details?.locationName || '', path: '' },
        ]}
      />
      <Box
        display="flex"
        flexDirection="column"
        gap={{ mobile: 3, desktop: 4 }}
        marginTop={{ mobile: 3, desktop: 5 }}
      >
        <Box
          display="grid"
          gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
          gap={{ mobile: 3, desktop: 4 }}
        >
          <Box>
            <Typography variant="subheading">
              {windTurbineCounterText}
            </Typography>
            <Typography variant="h1" sx={{ mt: 1.5 }}>
              {details?.locationName}
            </Typography>
          </Box>
          <Box
            display="grid"
            gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
            gap={1}
          >
            <Box sx={{ display: { mobile: 'none', desktop: 'block' } }} />
            <EnergyCounter
              variant="parkPage"
              label={t('pages.parkDetails.activeEnergyCounter')}
              value={`${activeEnergy.value} ${activeEnergy.label}`}
              tooltipTitle={t('pages.parkDetails.activeEnergyTooltip')}
            />
          </Box>
        </Box>
        {isMobile && weather && <TabsSectionStyled tabs={weatherTabs} />}
        {!isMobile && weather && (
          <WeatherWrapper>
            <WeatherHourly />
            <WeatherDaily />
          </WeatherWrapper>
        )}
        {details?.locationId !== undefined && (
          <WindTurbinesTable locationId={details.locationId} />
        )}
      </Box>
    </Wrapper>
  );
};
