import Typography from '@mui/material/Typography';
import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import App from 'App';
import { ProtectedRoutes } from 'components/common/ProtectedRoutes';
import { Login } from 'components/pages/Login';
import { AccessDenied } from 'components/pages/protected/AccessDenied';
import {
  CustomerUserContainer,
  CustomerUserHistoryLog,
  CustomerUserProfile,
} from 'components/pages/protected/CustomerUser';
import { EmailVerification } from 'components/pages/protected/EmailVerification';
import { ErrorReport } from 'components/pages/protected/ErrorReport';
import { ErrorsHistoryLogReport } from 'components/pages/protected/ErrorsHistoryLogReport';
import { MainLayout } from 'components/pages/protected/MainLayout';
import { NotFound } from 'components/pages/protected/NotFound';
import Notifications from 'components/pages/protected/Notifications';
import { Park } from 'components/pages/protected/Park';
import Parks from 'components/pages/protected/Parks';
import { Reports } from 'components/pages/protected/Reports';
import { UpdateProfile } from 'components/pages/protected/UpdateProfile';
import { Users } from 'components/pages/protected/Users';
import {
  WindTurbineGeneralInfo,
  WindTurbine,
  WindTurbineStatuses,
  WindTurbineStreaming,
} from 'components/pages/protected/WindTurbine';
import { WindTurbineHistoryLog } from 'components/pages/protected/WindTurbineHistoryLog';
import routePaths from 'constants/routePaths';
import { UserDetailsDtoUserRoleEnum } from 'openapi-api/admin-service';

export type HandleCustomType = {
  disallowedRoles?: UserDetailsDtoUserRoleEnum[];
};

export const appRoutes = [
  {
    path: routePaths.root,
    element: <Navigate replace to={routePaths.parks.root} />,
  },
  {
    path: routePaths.parks.root,
    children: [
      {
        path: routePaths.parks.root,
        element: <Parks />,
      },
      {
        path: routePaths.parks.specific(':locationId').root,
        children: [
          {
            path: routePaths.parks.specific(':locationId').root,
            element: <Park />,
          },
          {
            path: routePaths.parks
              .specific(':locationId')
              .windTurbine(':publicId').root,
            element: <WindTurbine />,
            children: [
              {
                path: routePaths.parks
                  .specific(':locationId')
                  .windTurbine(':publicId').root,
                element: <Navigate replace to="general" />,
              },
              {
                path: 'general',
                element: <WindTurbineGeneralInfo />,
              },
              {
                path: 'statuses',
                element: <WindTurbineStatuses />,
              },
              {
                path: 'streaming',
                element: <WindTurbineStreaming />,
              },
            ],
          },
          {
            path: routePaths.parks
              .specific(':locationId')
              .windTurbine(':publicId').errorReport,
            element: <ErrorReport />,
          },
          {
            path: routePaths.parks
              .specific(':locationId')
              .windTurbine(':publicId').errorsHistoryLogReport,
            element: <ErrorsHistoryLogReport />,
          },
          {
            path: routePaths.parks
              .specific(':locationId')
              .windTurbine(':publicId').historyLog.root,
            handle: {
              disallowedRoles: [UserDetailsDtoUserRoleEnum.ADMIN_PORTAL_VIEWER],
            },
            children: [
              {
                path: routePaths.parks
                  .specific(':locationId')
                  .windTurbine(':publicId').historyLog.root,
                element: <Navigate replace to="all" />,
              },
              {
                path: routePaths.parks
                  .specific(':locationId')
                  .windTurbine(':publicId')
                  .historyLog.specific(':type'),
                element: <WindTurbineHistoryLog />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: routePaths.reports,
    element: <Reports />,
  },
  {
    path: routePaths.users.root,
    element: <Users />,
    handle: {
      disallowedRoles: [
        UserDetailsDtoUserRoleEnum.USER_PORTAL_ADMIN,
        UserDetailsDtoUserRoleEnum.USER_PORTAL_VIEWER,
      ],
    },
  },
  {
    path: routePaths.users.profile(':userId').root,
    element: <CustomerUserContainer />,
    handle: {
      disallowedRoles: [
        UserDetailsDtoUserRoleEnum.USER_PORTAL_ADMIN,
        UserDetailsDtoUserRoleEnum.USER_PORTAL_VIEWER,
      ],
    },
    children: [
      {
        path: routePaths.users.profile(':userId').root,
        element: <CustomerUserProfile />,
      },
      {
        path: routePaths.users.profile(':userId').historyLog,
        element: <CustomerUserHistoryLog />,
      },
    ],
  },
  {
    path: routePaths.notifications.root,
    children: [
      {
        path: routePaths.notifications.root,
        element: <Navigate replace to="all" />,
      },
      {
        path: routePaths.notifications.specific(':type'),
        element: <Notifications />,
      },
    ],
  },
  {
    path: routePaths.updateProfile,
    element: <UpdateProfile />,
  },
  {
    path: routePaths.accessDenied,
    element: <AccessDenied />,
  },
  {
    path: routePaths.notFound,
    element: <NotFound />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

const routes: RouteObject[] = [
  {
    path: routePaths.root,
    element: <App />,
    children: [
      {
        path: routePaths.root,
        element: <ProtectedRoutes />,
        children: [
          {
            path: routePaths.root,
            element: <MainLayout />,
            children: appRoutes,
          },
        ],
      },
      {
        path: routePaths.login,
        element: <Login />,
      },
      {
        path: routePaths.emailVerification(':verificationToken'),
        element: <EmailVerification />,
      },
      {
        path: '*',
        element: <Typography variant="h1">{'404 Not Found'}</Typography>,
      },
    ],
  },
];

export default routes;
