import { styled } from '@mui/material';

export const EnergyCounterIconWrapper = styled('div', {
  shouldForwardProp: (propName) => propName !== 'variant',
})<{
  variant: 'parkCard' | 'parkPage';
}>(({ theme, variant }) => ({
  display: 'flex',
  padding: theme.spacing(variant === 'parkCard' ? 1.375 : 0.75),
  borderRadius: variant === 'parkCard' ? '4px' : '6px',
  backgroundColor: theme.palette.green[300],
}));
