import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  AccordionDetails,
  AccordionSummary,
  Drawer,
  Grid,
  IconButton,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState, MouseEvent, FC, useCallback, useEffect } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { FilterWrapper } from './components/FilterWrapper';
import FilterButton from './styles/FilterButton';
import MenuAccordion from './styles/MenuAccordion';
import MenuWrapper from './styles/MenuWrapper';
import SwipeDrawerWrapper from './styles/SwipeDrawerWrapper';
import TabletTopLine from './styles/TabletTopLine';
import { LocalValues } from './types';
import CommonButton from '../../../../CommonButton';
import { Close, Options } from 'components/icons';
import { FilterItem, FilterModel, FilterValues } from 'types/general';

type Props = {
  checkedFilterValues?: Record<string, FilterItem>;
  filterValues: FilterValues;
  onFilterModelChange: (filterModel: FilterModel) => void;
  light?: boolean;
  sx?: SxProps;
};

export const FilterMenu: FC<Props> = ({
  checkedFilterValues,
  filterValues,
  onFilterModelChange,
  light = false,
  sx,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [localValues, setLocalValues] = useState<LocalValues>({});
  const theme = useTheme();
  const { t } = useTranslation();

  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const isTablet = useMediaQuery(theme.breakpoints.only('tablet'));
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));

  useEffect(
    () => setLocalValues(checkedFilterValues || {}),
    [checkedFilterValues],
  );
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderContent = useCallback(
    (width: string) => (
      <Grid>
        {Object.entries(filterValues).map(
          ([field, { label: labelText, values, type }]) => (
            <MenuAccordion sx={{ width }} defaultExpanded key={field}>
              <AccordionSummary
                expandIcon={<ExpandLessIcon />}
                aria-controls={`${field}-content`}
                id={`${field}-header`}
              >
                <Typography variant="subheading" color="grey.600">
                  {labelText}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <FilterWrapper
                  values={values}
                  field={field}
                  label={labelText}
                  localValues={localValues}
                  setLocalValues={setLocalValues}
                  type={type}
                />
              </AccordionDetails>
            </MenuAccordion>
          ),
        )}
        <Grid
          padding={2.5}
          display="grid"
          gap={1}
          gridTemplateColumns="1fr auto"
        >
          <CommonButton
            variant="contained"
            onClick={() => {
              Object.entries(localValues).forEach(([field, { value }]) =>
                onFilterModelChange({ field, value }),
              );
              handleClose();
            }}
            data-testid="apply"
            sx={{ width: '160px' }}
          >
            {t('components.filterMenu.apply')}
          </CommonButton>
          <CommonButton
            variant="text"
            sx={(th) => ({
              px: 0,
              textTransform: 'none',
              color: th.palette.grey[800],
            })}
            onClick={() => {
              Object.entries(localValues).forEach(([field]) =>
                onFilterModelChange({ field, value: undefined }),
              );
              handleClose();
            }}
            data-testid="clear"
          >
            {t('components.filterMenu.clearAll')}
          </CommonButton>
        </Grid>
      </Grid>
    ),
    [localValues, filterValues, onFilterModelChange, t],
  );

  return (
    <div>
      <FilterButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        isOpen={open}
        data-testid="open-menu"
        light={light}
        disableElevation
        sx={sx}
      >
        <Typography
          variant="bodyM"
          color={light ? 'black.900' : 'black.500'}
          sx={{ display: { mobile: 'none', tablet: 'block' } }}
        >
          {t('components.filterMenu.filters')}
        </Typography>
        <Options />
      </FilterButton>
      {isDesktop && (
        <MenuWrapper
          data-testid="desktop-menu"
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {renderContent('340px')}
        </MenuWrapper>
      )}
      {isTablet && (
        <Drawer
          anchor="right"
          open={open}
          onClose={handleClose}
          data-testid="tablet-menu"
        >
          <TabletTopLine px={2.5} py={1.5}>
            <Typography variant="h5">
              {t('components.filterMenu.filters')}
            </Typography>
            <IconButton onClick={handleClose} sx={{ p: 0 }}>
              <Close />
            </IconButton>
          </TabletTopLine>
          {renderContent('335px')}
        </Drawer>
      )}
      {isMobile && (
        <SwipeDrawerWrapper
          onOpen={handleClick}
          anchor="bottom"
          open={open}
          onClose={handleClose}
          data-testid="mobile-menu"
        >
          <TabletTopLine px={2.5} py={1.5}>
            <Typography variant="h5">
              {t('components.filterMenu.filters')}
            </Typography>
            <IconButton onClick={handleClose} sx={{ p: 0 }} data-testid="close">
              <Close />
            </IconButton>
          </TabletTopLine>
          {renderContent('100%')}
        </SwipeDrawerWrapper>
      )}
    </div>
  );
};
