import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BreadcrumbItem } from 'components/common/Breadcrumbs';
import { UserHistoryLog } from 'components/shared/UserHistoryLog';
import routePaths from 'constants/routePaths';
import { useCustomerUserContext } from 'contexts/customerUser';

export const CustomerUserHistoryLog: FC = () => {
  const { t } = useTranslation();

  const { customerUser } = useCustomerUserContext();

  const breadcrumbs = useMemo(
    (): BreadcrumbItem[] => [
      {
        label: t('pages.users.title'),
        path: routePaths.users.root,
      },
      {
        label: customerUser?.username as string,
        path: routePaths.users.profile(customerUser?.id!).root,
      },
      {
        label: t('components.historyLog.title'),
        path: routePaths.users.profile(customerUser?.id!).historyLog,
      },
    ],
    [customerUser, t],
  );

  return (
    <UserHistoryLog userDetails={customerUser} breadcrumbs={breadcrumbs} />
  );
};
