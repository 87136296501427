const windTurbinePaths = (rootPath: string, publicId: string | number) => {
  const wtRootPath = `${rootPath}/wind-turbines/${publicId}`;

  return {
    root: wtRootPath,
    general: `${wtRootPath}/general`,
    statuses: `${wtRootPath}/statuses`,
    streaming: `${wtRootPath}/streaming`,
    errorReport: `${wtRootPath}/errorReport`,
    errorsHistoryLogReport: `${wtRootPath}/errorsHistoryLogReport`,
    historyLog: {
      root: `${wtRootPath}/history`,
      specific: (type: string) => `${wtRootPath}/history/${type}`,
    },
  };
};

const routePaths = {
  root: '/',
  parks: {
    root: '/parks',
    specific: (locationId: string | number) => {
      const parkRootPath = `${routePaths.parks.root}/${locationId}`;

      return {
        root: parkRootPath,
        windTurbine: (publicId: string | number) =>
          windTurbinePaths(parkRootPath, publicId),
      };
    },
  },
  login: '/login',
  emailVerification: (verificationToken: string) =>
    `/email-verification/${verificationToken}`,
  reports: '/reports',
  users: {
    root: '/users',
    profile: (userId: string | number) => ({
      root: `/users/${userId}`,
      historyLog: `/users/${userId}/history`,
    }),
  },
  notifications: {
    root: '/notifications',
    specific: (type: string) => `/notifications/${type}`,
  },
  updateProfile: '/update-profile',
  accessDenied: '/access-denied',
  notFound: '/not-found',
};

export default routePaths;
