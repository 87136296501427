import { createContext } from 'react';

import { UserDetailsDto } from 'openapi-api/admin-service';

export interface ICustomerUserContext {
  customerUser?: UserDetailsDto;
  isLoading: boolean;
  fetch: () => Promise<void>;
}

export const CustomerUserContext = createContext<ICustomerUserContext>(null!);
