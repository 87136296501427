import { Box, SxProps } from '@mui/material';
import { FC } from 'react';

import { TooltipIcon, TooltipStyled } from './styles';

interface Props {
  title: string;
  className?: string;
  sx?: SxProps;
}

export const InfoTooltip: FC<Props> = ({ title, className, sx }) => {
  return (
    <TooltipStyled title={title} placement="top">
      <Box display="flex" className={className} sx={sx}>
        <TooltipIcon />
      </Box>
    </TooltipStyled>
  );
};
