import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const ActiveEnergy: FC<CommonSvgProps> = (props) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.193 23V20.335H11.3613C11.2199 20.335 11.0916 20.2522 11.0328 20.1221C10.9741 19.9926 10.9952 19.8401 11.0876 19.7325L16.7263 13.1209C16.8631 12.9597 17.1369 12.9597 17.2737 13.1209L22.9124 19.7325C23.0048 19.8407 23.0259 19.9926 22.9672 20.1221C22.9084 20.2516 22.7801 20.335 22.6387 20.335H20.807V23"
      stroke="#90D23B"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M21.2084 17.3333L21.2084 8.51309H22.382C22.5261 8.51309 22.6568 8.43058 22.7166 8.30167C22.7765 8.17276 22.755 8.0215 22.6608 7.91378L18.6956 3.37032C18.5562 3.20989 18.2773 3.20989 18.1379 3.37032L16.1553 5.64205"
      stroke="#90D23B"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M5.41855 19C5.33686 19 5.25438 18.9805 5.17904 18.9398C4.97363 18.8297 4.87212 18.5953 4.93398 18.3735L6.64624 12.2397H3.50247C3.30499 12.2397 3.12575 12.1264 3.04486 11.9491C2.96317 11.7726 2.99489 11.5648 3.12496 11.4187L10.4689 3.1687C10.6204 2.9984 10.871 2.95075 11.0764 3.05465C11.2818 3.15854 11.3889 3.38587 11.3365 3.60694L10.047 9.06657H13.4977C13.6959 9.06657 13.8752 9.18141 13.9561 9.35873C14.0369 9.53684 14.0044 9.74463 13.8728 9.89071L5.79526 18.8336C5.69692 18.9422 5.55892 19 5.41855 19Z"
      stroke="#90D23B"
      stroke-width="2"
      stroke-miterlimit="10"
    />
  </svg>
);
