import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { fieldsToSend } from './constants';
import { getFilterValues, getSortingItems } from './helpers';
import { GridControlsWrapper, PageWrapper, UserIdBlock } from './styles';
import { UserHistoryLogItem } from './UserHistoryLogItem';
import { UserStatusChip } from '../../pages/protected/Users/styles';
import { HistoryLogContent, HistoryLogPagination } from '../HistoryLog';
import { NoEventsBlock } from '../NoEventsBlock';
import { getUserStatusTranslate } from '../ProfilePage';
import { BreadcrumbItem, Breadcrumbs } from 'components/common/Breadcrumbs';
import { dateStringToObject } from 'components/common/DatePicker';
import { Sorting } from 'components/common/Sorting';
import {
  FilterMenu,
  Search,
  TablePaginationVariants,
} from 'components/common/Table/CommonTable';
import useApi from 'contexts/api';
import useUser from 'contexts/user';
import { UserDetailsDto } from 'openapi-api/admin-service';
import { FilterItem } from 'types/general';
import { formatObjFromQuery } from 'utils/functions/formatQuery';
import { getRowsPerPage } from 'utils/functions/getRowsPerPage';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';
import useDataGrid from 'utils/hooks/useDataGrid';

interface Props {
  userDetails?: UserDetailsDto;
  breadcrumbs: BreadcrumbItem[];
}

export const UserHistoryLog: FC<Props> = ({ userDetails, breadcrumbs }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

  const { historyLogControllerApi } = useApi();

  const { user } = useUser();

  const {
    searchParams,
    setSearchParams,
    onSearch,
    onSortModelChange,
    onFilterModelChange,
    paginationModel,
    onPaginationModelChange,
  } = useDataGrid();

  const sortingItems = useMemo(() => getSortingItems(t), [t]);

  const filterValues = useMemo(() => getFilterValues(t), [t]);

  const checkedFilterValues: Record<string, FilterItem> = useMemo(
    () => ({
      dateRange: { value: searchParams.get('dateRange') },
    }),
    [searchParams],
  );

  const gridControls = useMemo(
    () => (
      <GridControlsWrapper>
        <Search onSearch={onSearch} />
        <Sorting
          items={sortingItems}
          onSortModelChange={onSortModelChange}
          light
        />
        <FilterMenu
          filterValues={filterValues}
          checkedFilterValues={checkedFilterValues}
          onFilterModelChange={onFilterModelChange}
          light
        />
      </GridControlsWrapper>
    ),
    [
      onSearch,
      sortingItems,
      onSortModelChange,
      filterValues,
      checkedFilterValues,
      onFilterModelChange,
    ],
  );

  const getUserHistoryLog = useCallback(async () => {
    if (typeof userDetails?.id === 'undefined') return;

    const objFromQuery = formatObjFromQuery(fieldsToSend, searchParams);
    const dateObj = dateStringToObject(objFromQuery.dateRange as string);

    if (!objFromQuery.size || !objFromQuery.sort) return;

    try {
      return (
        await historyLogControllerApi.getHistoryLogs({
          ...objFromQuery,
          userId: userDetails?.id,
          fromCreateDatetime: dateObj.from,
          toCreateDatetime: dateObj.to,
        })
      ).data;
    } catch {}
  }, [userDetails?.id, searchParams, historyLogControllerApi]);

  const { resource: historyLog } = useAsyncResourceWithPulling({
    fetchResource: getUserHistoryLog,
    pullingInterval: 30,
  });

  const rowsPerPageOptions = useMemo(
    () => getRowsPerPage(t, isMobile),
    [isMobile, t],
  );

  useEffect(() => {
    setSearchParams(
      (prev) => {
        if (prev.get('sort') === null) {
          prev.set('sort', 'create_datetime,DESC');
        }

        if (prev.get('size') === null) {
          prev.set('size', '20');
        }

        return prev;
      },
      { replace: true },
    );
  }, [setSearchParams]);

  return (
    <PageWrapper>
      <Breadcrumbs items={breadcrumbs} />
      <Box display="flex" flexDirection="column" gap={3} mt={4}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h1">
            {t('components.historyLog.title')}
          </Typography>
          {!isMobile && gridControls}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
            <Typography variant="h5">{userDetails?.username}</Typography>
            <UserIdBlock>
              <Typography variant="bodySStrong">{`ID: ${userDetails?.id}`}</Typography>
            </UserIdBlock>
            <Typography variant="bodySStrong">
              {`${t('masterAccount')}: ${user.username}`}
            </Typography>
            <Typography variant="bodySStrong">
              {t(`userRoles.${userDetails?.userRole}`)}
            </Typography>
            {userDetails?.email && (
              <Typography variant="bodySStrong">{userDetails.email}</Typography>
            )}
          </Box>
          {userDetails?.status && (
            <UserStatusChip status={userDetails.status}>
              {getUserStatusTranslate({ status: userDetails.status, t })}
            </UserStatusChip>
          )}
        </Box>
      </Box>
      {isMobile && gridControls}
      <Box mt={{ mobile: 4, desktop: 6.5 }}>
        {historyLog?.empty ? (
          <NoEventsBlock />
        ) : (
          <HistoryLogContent>
            {historyLog?.content?.map((item) => (
              <UserHistoryLogItem
                key={item.id}
                data={item}
                currentUserRole={userDetails?.userRole}
              />
            ))}
            <HistoryLogPagination
              variant={TablePaginationVariants.DEFAULT}
              count={historyLog?.totalElements}
              page={paginationModel.page}
              rowsPerPage={paginationModel.pageSize}
              rowsPerPageOptions={rowsPerPageOptions}
              onPaginationModelChange={onPaginationModelChange}
            />
          </HistoryLogContent>
        )}
      </Box>
    </PageWrapper>
  );
};
