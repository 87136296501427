import * as yup from 'yup';

import { TranslationFunction } from '../../../../types/general';

const validationSchema = (t: TranslationFunction) =>
  yup.object({
    subject: yup
      .string()
      .required(t('form.errors.fieldRequired'))
      .max(200, t('popup.supportRequest.subjectError')),
    description: yup
      .string()
      .required(t('form.errors.fieldRequired'))
      .max(2000, t('popup.supportRequest.descriptionError')),
    assetPublicIds: yup.array().of(yup.string().required()),
  });

export default validationSchema;
