import { Typography, tooltipClasses } from '@mui/material';
import { FC, MouseEventHandler, ReactElement, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { CloseIcon, IconWrapper, TooltipStyled } from './styled';

interface Props {
  errorsCount?: number;
  remindersCount?: number;
  warningsCount?: number;
  open?: boolean;
  onClick?: () => void;
  onClose?: () => void;
  children: ReactElement;
}

const NotificationsTooltip: FC<Props> = ({
  errorsCount,
  remindersCount,
  warningsCount,
  open = false,
  onClick,
  onClose,
  children,
}) => {
  const { t } = useTranslation();

  const onTooltipClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.stopPropagation();
      onClick?.();
    },
    [onClick],
  );

  const onCloseIconClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.stopPropagation();
      onClose?.();
    },
    [onClose],
  );

  return (
    <TooltipStyled
      open={open}
      arrow
      slotProps={{
        popper: {
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
              {
                marginTop: {
                  mobile: 1,
                  desktop: 1.5,
                },
              },
          },
        },
        tooltip: { onClick: onTooltipClick },
      }}
      title={
        <>
          <Typography variant="h5" sx={{ mb: 1 }} color="black.900">
            {t('notifications.tooltip.title')}
          </Typography>
          <Typography variant="bodyXS" color="black.900">
            <Trans
              i18nKey={'notifications.tooltip.text'}
              values={{ errorsCount, warningsCount, remindersCount }}
            />
          </Typography>
          <IconWrapper
            onClick={onCloseIconClick}
            data-testid="tooltip-close-icon"
          >
            <CloseIcon />
          </IconWrapper>
        </>
      }
    >
      {children}
    </TooltipStyled>
  );
};

export default NotificationsTooltip;
