import axios from 'axios';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useResponsePopup from '../../contexts/responsePopup';

export const useHandleResponseError = () => {
  const { t } = useTranslation();
  const { openPopup, closePopup } = useResponsePopup();

  const handleResponse = useCallback(
    (e: unknown) => {
      let message = t('components.errorPopup.subtitle');
      if (axios.isAxiosError(e) && e.response?.data.message) {
        message = e.response?.data.message;
      }
      openPopup({
        title: t('components.errorPopup.title'),
        subtitle: message,
        secondaryButton: {
          text: t('buttons.cancel'),
          onClick: closePopup,
        },
      });
    },
    [closePopup, openPopup, t],
  );

  return { handleResponse };
};
