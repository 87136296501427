import React, {
  ChangeEvent,
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { WindTurbineContext, IWindTurbineContext } from './WindTurbineContext';
import { useAsyncResourceWithPulling } from '../../utils/hooks/useAsyncResourceWithPulling';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import { isNotFoundError } from 'contexts/auth/helpers';
import useGlobalLoader from 'contexts/globalLoader';
import {
  AssetCommandDto,
  AssetCommandDtoOperationalStatusEnum,
} from 'openapi-api/admin-service';

const WindTurbineProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { publicId } = useParams();
  const { commandControllerApi, windTurbineControllerApi } = useApi();

  const getWindTurbineData = useCallback(async () => {
    if (typeof publicId === 'undefined') {
      return;
    }
    try {
      const data = (await windTurbineControllerApi.getWindTurbine({ publicId }))
        .data;
      return data;
    } catch (error) {
      if (isNotFoundError(error)) {
        navigate(routePaths.notFound);
      }
    }
  }, [publicId, windTurbineControllerApi, navigate]);

  const {
    resource: windTurbineData,
    isLoading: isWindTurbineDataLoading,
    fetch,
  } = useAsyncResourceWithPulling({
    fetchResource: getWindTurbineData,
    pullingInterval: 30,
  });

  const handleTurbineControlClick = useCallback(
    async (command: Omit<AssetCommandDto, 'assetPublicId'>) => {
      if (typeof publicId === 'undefined') {
        return;
      }
      try {
        const data = (
          await commandControllerApi.command({
            assetCommandDto: {
              assetPublicId: publicId,
              ...command,
            },
          })
        ).data;
        return data;
      } catch {}
    },
    [publicId, commandControllerApi],
  );

  const handleTurbineActivityChange = useCallback(
    async (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      showLoader();
      try {
        await handleTurbineControlClick({
          operationalStatus: checked
            ? AssetCommandDtoOperationalStatusEnum.ACTIVATED
            : AssetCommandDtoOperationalStatusEnum.DEACTIVATED,
        });
        await fetch();
      } catch {
      } finally {
        hideLoader();
      }
    },
    [showLoader, handleTurbineControlClick, fetch, hideLoader],
  );

  const contextValue: IWindTurbineContext = useMemo(
    () => ({
      windTurbine: windTurbineData,
      refetch: fetch,
      handleTurbineActivityChange,
      isLoading: isWindTurbineDataLoading,
    }),
    [
      windTurbineData,
      fetch,
      handleTurbineActivityChange,
      isWindTurbineDataLoading,
    ],
  );

  return (
    <WindTurbineContext.Provider value={contextValue}>
      {children}
    </WindTurbineContext.Provider>
  );
};

export default WindTurbineProvider;
