import * as yup from 'yup';

import { TranslationFunction } from 'types/general';

export const validationSchema = (
  t: TranslationFunction,
  isSuperAdmin?: boolean,
) =>
  yup.object({
    ...(isSuperAdmin
      ? {
          organizationId: yup.string(),
        }
      : {
          organizationId: yup.string().required(t('form.errors.fieldRequired')),
        }),
    username: yup.string().required(t('form.errors.fieldRequired')),
    password: yup.string().required(t('form.errors.fieldRequired')),
  });
