import { Chip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportRequestHistoryDtoStatusEnum } from 'openapi-api/report-service';

interface Props {
  status?: ReportRequestHistoryDtoStatusEnum;
}

export const ReportItemChip: FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  if (status === ReportRequestHistoryDtoStatusEnum.READY) {
    return (
      <Chip
        label={t('pages.reports.historyChipReady')}
        color="success"
        sx={{ width: '90px' }}
      />
    );
  }
  if (status === ReportRequestHistoryDtoStatusEnum.PENDING) {
    return (
      <Chip
        label={t('pages.reports.historyChipPending')}
        color="warning"
        sx={{ width: '90px' }}
      />
    );
  }

  return <Chip label={t('error')} color="error" sx={{ width: '90px' }} />;
};
