import { styled } from '@mui/material';

import { UserDetailsDtoStatusEnum } from 'openapi-api/admin-service';

export const UserStatusChip = styled('div', {
  shouldForwardProp: (propName) => propName !== 'status',
})<{ status?: UserDetailsDtoStatusEnum }>(({ theme, status }) => {
  let backgroundColor: string | undefined;

  switch (status) {
    case UserDetailsDtoStatusEnum.ACTIVE:
      backgroundColor = theme.palette.green[200];
      break;
    case UserDetailsDtoStatusEnum.INACTIVE:
      backgroundColor = theme.palette.grey[50];
      break;
    case UserDetailsDtoStatusEnum.DELETED:
      backgroundColor = theme.palette.warning[200];
      break;
    default:
      backgroundColor = theme.palette.black[500];
  }

  return {
    padding: theme.spacing(0.75, 2),
    borderRadius: '16px',
    ...theme.typography.subheading,
    lineHeight: '13px',
    color: theme.palette.black[500],
    backgroundColor,
  };
});
