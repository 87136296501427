import { useContext } from 'react';

import { CustomerUserContext } from './CustomerUserContext';
import { CustomerUserProvider } from './CustomerUserProvider';

const useCustomerUserContext = () => {
  const context = useContext(CustomerUserContext);

  if (typeof context === 'undefined' || context === null) {
    throw new Error(
      'useCustomerUserContext must be used within an CustomerUserProvider',
    );
  }

  return context;
};

export { useCustomerUserContext, CustomerUserProvider };
