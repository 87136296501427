import { styled } from '@mui/material';

const Wrapper = styled('div', {
  shouldForwardProp: (propName) =>
    propName !== 'isPopperOpened' && propName !== 'light',
})<{ isPopperOpened?: boolean; light?: boolean }>(({
  theme,
  isPopperOpened,
  light,
}) => {
  const bgColor = light ? theme.palette.grey[50] : theme.palette.green[100];
  const bgColorOpened = light
    ? theme.palette.grey[100]
    : theme.palette.white.main;
  const textColor = light ? theme.palette.black[900] : theme.palette.black[600];
  const arrowColor = theme.palette.grey[600];
  const arrowColorOpened = theme.palette.black[500];

  return {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    padding: theme.spacing(1.5, 2.5),
    borderRadius: '590px',
    backgroundColor: isPopperOpened ? bgColorOpened : bgColor,
    whiteSpace: 'nowrap',
    userSelect: 'none',
    cursor: 'pointer',

    '& > .MuiTypography-root': {
      color: textColor,
    },

    '& > svg': {
      transform: isPopperOpened ? 'rotate(180deg)' : 'rotate(0deg)',
      '& > path': {
        stroke: isPopperOpened ? arrowColorOpened : arrowColor,
      },
    },

    [theme.breakpoints.down('tablet')]: {
      width: '100%',
      justifyContent: 'center',
      padding: theme.spacing(1.5, 1),
    },
  };
});

export default Wrapper;
