import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DownloadIcon } from './styles';
import CommonButton from 'components/common/CommonButton';
import { ReportReadyDto } from 'openapi-api/admin-service';
import { getStringFromISODate } from 'utils/functions/getStringFromISODate';

interface Props {
  details: ReportReadyDto;
  extended: boolean;
}

const TemplateReportReady: FC<Props> = ({ details, extended }) => {
  const { link, startDatetime, endDatetime, generationDatetime } = details;
  const { t } = useTranslation();

  return (
    <>
      <Typography variant={extended ? 'bodyM' : 'bodyS'}>
        <Trans
          i18nKey="notifications.reportReady"
          values={{
            reportPeriodFrom: startDatetime
              ? dayjs(startDatetime).format('DD/MM/YYYY HH:mm')
              : 'undefined',
            reportPeriodTo: endDatetime
              ? dayjs(endDatetime).format('DD/MM/YYYY HH:mm')
              : 'undefined',
            requestedDatetime: generationDatetime
              ? getStringFromISODate(generationDatetime)
              : 'undefined',
          }}
          components={[
            <Typography
              key={`reportReady-text-${link}`}
              variant="bodySStrong"
            />,
          ]}
        />
      </Typography>
      <Box
        component="a"
        download
        href={link}
        target="_blank"
        sx={{ width: 'fit-content', mt: 2 }}
      >
        <CommonButton size="medium" startIcon={<DownloadIcon />}>
          {t('pages.reports.downloadCsv')}
        </CommonButton>
      </Box>
    </>
  );
};

export default TemplateReportReady;
