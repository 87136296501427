import { Dispatch, SetStateAction, createContext } from 'react';

import {
  AssetDetailsDto,
  GrafanaPanelParametersDto,
  LocationDto,
} from 'openapi-api/admin-service';

export interface IReportPeriod {
  from: string;
  to: string;
}

export interface IReportValues {
  locations: LocationDto[];
  assets: AssetDetailsDto[];
  period: IReportPeriod;
}

export interface IReportContext {
  reportValues?: IReportValues;
  setReportValues: Dispatch<SetStateAction<IReportValues | undefined>>;
  reportPanels?: { [key: string]: GrafanaPanelParametersDto[] };
  reportAssetsHasHistoryLogs: { [publicId: string]: boolean };
  assetPublicIds: string[];
  loadingPanels: boolean;
}

export const ReportContext = createContext<IReportContext>(null!);
