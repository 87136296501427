import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { defaultValues } from './constants';
import { FormWrapper } from './styles';
import validationSchema from './validationSchema';
import CommonButton from '../../CommonButton';
import CommonDialog from '../../CommonDialog';
import { FormSelect } from '../../form/Select';
import TextField from '../../form/TextField';
import { ButtonsWrapper } from '../styles';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import useApi from 'contexts/api';
import useGlobalData from 'contexts/globalData';
import useResponsePopup from 'contexts/responsePopup';
import useUser from 'contexts/user';
import {
  AssetNameDto,
  UserIssueRequestSubmitDto,
} from 'openapi-api/admin-service';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';
import { useHandleResponseError } from 'utils/hooks/useHandleResponseError';

export const SupportRequest = () => {
  const { t } = useTranslation();
  const { setIsSendSupportRequestOpened } = useGlobalData();
  const { user } = useUser();
  const { assetControllerApi, userIssueRequestControllerApi } = useApi();
  const { openPopup, closePopup: closeResponsePopup } = useResponsePopup();
  const form = useForm<UserIssueRequestSubmitDto>({
    resolver: yupResolver(validationSchema(t)),
    defaultValues,
    mode: 'onSubmit',
  });
  const { handleSubmit: handleSubmitForm, watch } = form;
  const { handleResponse } = useHandleResponseError();
  const [pending, setPending] = useState(false);

  const subject = watch('subject');
  const description = watch('description');

  const isSubmitDisabled = useMemo(
    () => !subject || !description || pending,
    [subject, description, pending],
  );

  const loadTurbines = useCallback(async () => {
    let result: AssetNameDto[];
    try {
      result = (await assetControllerApi.getAssetNames()).data;
    } catch {
      result = [];
    }

    return result;
  }, [assetControllerApi]);

  const { resource: turbines } = useAsyncResourceWithPulling({
    fetchResource: loadTurbines,
    pullingInterval: 30,
  });

  const handleSubmit = handleSubmitForm(
    async (data: UserIssueRequestSubmitDto) => {
      try {
        setPending(true);
        await userIssueRequestControllerApi.createRequest({
          userIssueRequestSubmitDto: data,
        });
        setIsSendSupportRequestOpened(false);
        openPopup({
          variant: NotificationPopupVariants.Success,
          title: t('popup.supportRequest.successTitle'),
          primaryButton: {
            text: t('continueSession'),
            onClick: closeResponsePopup,
          },
        });
      } catch (e) {
        handleResponse(e);
      }
      setPending(false);
    },
  );

  return (
    <CommonDialog
      onClose={() => setIsSendSupportRequestOpened(false)}
      open
      width={540}
      align="left"
    >
      <Typography sx={{ mb: 5 }} variant="h2">
        {t('popup.supportRequest.title')}
      </Typography>
      <Typography sx={{ mb: 3 }} variant="h4">
        {user.username}
      </Typography>
      <FormProvider {...form}>
        <FormWrapper onSubmit={handleSubmit} sx={{ alignItems: 'flex-start' }}>
          <Typography sx={{ mb: 1.5 }} variant="bodyM">
            {t('popup.supportRequest.subject')}
          </Typography>
          <TextField
            sx={{ mb: 4 }}
            name="subject"
            label={t('popup.supportRequest.specifyIssue')}
            fullWidth
          />
          <Typography sx={{ mb: 1.5 }} variant="bodyM">
            {t('popup.supportRequest.selectTurbine')}
          </Typography>
          <FormSelect
            name="assetPublicIds"
            sx={{ mb: 4 }}
            items={
              turbines?.map(({ assetName, publicId }) => ({
                label: assetName as string,
                value: publicId as string,
              })) || []
            }
            multiple
            fullWidth
            label={t('popup.supportRequest.turbineName')}
          />
          <Typography sx={{ mb: 1.5 }} variant="bodyM">
            {t('popup.supportRequest.description')}
          </Typography>
          <TextField
            sx={(theme) => ({
              mb: 4,
              '.MuiInputBase-input::placeholder': {
                color: theme.palette.grey[800],
                opacity: '1',
              },
            })}
            name="description"
            placeholder={t('popup.supportRequest.descriptionText')}
            fullWidth
            multiline
            minRows={3}
            maxRows={3}
          />
          <ButtonsWrapper
            paddingTop={6.5}
            display="flex"
            gap={2}
            justifyContent="center"
          >
            <CommonButton
              variant="contained"
              data-testid="submit"
              type="submit"
              disabled={isSubmitDisabled}
              sx={{
                width: { mobile: '226px', tablet: 'auto', desktop: '100%' },
              }}
            >
              {t('buttons.submit')}
            </CommonButton>
            <CommonButton
              variant="outlined"
              onClick={() => setIsSendSupportRequestOpened(false)}
              data-testid="close-button"
              sx={{
                width: {
                  mobile: 'fit-content',
                  tablet: 'auto',
                  desktop: '100%',
                },
              }}
            >
              {t('cancel')}
            </CommonButton>
          </ButtonsWrapper>
        </FormWrapper>
      </FormProvider>
    </CommonDialog>
  );
};
