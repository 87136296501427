import { FC } from 'react';
import { useOutlet } from 'react-router-dom';

import { CustomerUserProvider } from 'contexts/customerUser';

export const CustomerUserContainer: FC = () => {
  const outlet = useOutlet();

  return <CustomerUserProvider>{outlet}</CustomerUserProvider>;
};
