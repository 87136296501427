import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useCallback, MouseEvent, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { WeatherPreview } from './components';
import {
  BadgeStyled,
  MainContentWrapper,
  ParkItemsCounter,
  Wrapper,
} from './styles';
import { ParkItemState } from './types';
import { EnergyCounter } from '../EnergyCounter';
import { BadgeSize, BadgeVariant } from 'components/common/Badge/types';
import { ChevronRightL } from 'components/icons';
import routePaths from 'constants/routePaths';
import {
  LocationWithWindTurbineMetricsDto,
  WindTurbineDtoOperationalStatusEnum,
  WindTurbineDtoStatusEnum,
} from 'openapi-api/admin-service';
import { convertWh } from 'utils/functions/converters';

const ParkStatesBadgeMapping = {
  [ParkItemState.Active]: BadgeVariant.Active,
  [ParkItemState.Error]: BadgeVariant.Error,
  [ParkItemState.Inactive]: BadgeVariant.Inactive,
};

interface Props {
  data: LocationWithWindTurbineMetricsDto;
}

export const ParkCard: FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    locationId,
    locationName,
    turbineCount,
    activeStatusCount,
    inactiveStatusCount,
    errorStatusCount,
    totalActiveEnergy,
  } = data;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));

  const onBadgeClick = useCallback(
    (e: MouseEvent<HTMLElement>, search: string) => {
      e.preventDefault();
      navigate({
        pathname: routePaths.parks.specific(locationId!).root,
        search,
      });
    },
    [navigate, locationId],
  );

  const activeEnergy = useMemo(
    () => convertWh(totalActiveEnergy || 0, t),
    [totalActiveEnergy, t],
  );

  const badges = useMemo(
    () => (
      <Box
        display="flex"
        gap={{ mobile: 1, desktop: 0.5 }}
        mt={{ mobile: 3, desktop: 0 }}
      >
        {!!activeStatusCount && (
          <BadgeStyled
            key={ParkItemState.Active}
            variant={ParkStatesBadgeMapping[ParkItemState.Active]}
            size={BadgeSize.Meduim}
            text={
              isDesktop ? (
                <Trans
                  i18nKey={`pages.parks.card.itemsStates.${[
                    ParkItemState.Active,
                  ]}`}
                  values={{ count: activeStatusCount }}
                />
              ) : (
                activeStatusCount
              )
            }
            onClick={(e) =>
              onBadgeClick(
                e,
                `?operationalStatus=${WindTurbineDtoOperationalStatusEnum.ACTIVATED}`,
              )
            }
            dataTestId="park-active-badge"
          />
        )}
        {!!inactiveStatusCount && (
          <BadgeStyled
            key={ParkItemState.Inactive}
            variant={ParkStatesBadgeMapping[ParkItemState.Inactive]}
            size={BadgeSize.Meduim}
            text={
              isDesktop ? (
                <Trans
                  i18nKey={`pages.parks.card.itemsStates.${[
                    ParkItemState.Inactive,
                  ]}`}
                  values={{ count: inactiveStatusCount }}
                />
              ) : (
                inactiveStatusCount
              )
            }
            onClick={(e) =>
              onBadgeClick(
                e,
                `?operationalStatus=${WindTurbineDtoOperationalStatusEnum.DEACTIVATED}`,
              )
            }
            dataTestId="park-inactive-badge"
          />
        )}
        {!!errorStatusCount && (
          <BadgeStyled
            key={ParkItemState.Error}
            variant={ParkStatesBadgeMapping[ParkItemState.Error]}
            size={BadgeSize.Meduim}
            text={
              isDesktop ? (
                <Trans
                  i18nKey={`pages.parks.card.itemsStates.${[
                    ParkItemState.Error,
                  ]}`}
                  values={{ count: errorStatusCount }}
                />
              ) : (
                errorStatusCount
              )
            }
            onClick={(e) =>
              onBadgeClick(e, `?status=${WindTurbineDtoStatusEnum.ERROR}`)
            }
            dataTestId="park-error-badge"
          />
        )}
      </Box>
    ),
    [
      activeStatusCount,
      inactiveStatusCount,
      errorStatusCount,
      onBadgeClick,
      isDesktop,
    ],
  );

  return (
    <Wrapper>
      <MainContentWrapper>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {isDesktop && badges}
          <ParkItemsCounter>
            <Typography variant="bodyMStrong">
              <Trans
                i18nKey="pages.parks.card.items"
                values={{ itemsCount: turbineCount }}
              />
            </Typography>
            <ChevronRightL />
          </ParkItemsCounter>
        </Box>
        <Typography variant="h3" sx={{ mt: { mobile: 1, desktop: 3 } }}>
          {locationName}
        </Typography>
        {!isDesktop && badges}
        <Box mt={{ mobile: 3, desktop: 2.5 }}>
          <EnergyCounter
            variant="parkCard"
            label={`${t('pages.parks.card.activeEnergy')}, ${
              activeEnergy.label
            }`}
            value={activeEnergy.value}
            tooltipTitle={t('pages.parkDetails.activeEnergyTooltip')}
          />
        </Box>
      </MainContentWrapper>
      <WeatherPreview weather={data.weather} />
    </Wrapper>
  );
};
