import React, { useEffect, FC, useMemo } from 'react';
import {
  useLocation,
  useMatches,
  useNavigate,
  useOutlet,
} from 'react-router-dom';

import GlobalLoader from 'components/common/GlobalLoader';
import routePaths from 'constants/routePaths';
import { HandleCustomType } from 'constants/routes';
import useAuth from 'contexts/auth';
import { ProtectedRoutesProvider } from 'contexts/ProtectedRoutesProvider';
import useUser from 'contexts/user';

interface Props {
  loginPath?: string;
}

export const ProtectedRoutes: FC<Props> = ({
  loginPath = routePaths.login,
}) => {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const location = useLocation();
  const { dataExists, tokenApplied } = useAuth();
  const { user } = useUser();
  const matches = useMatches();

  const permissionDeniedByRole = useMemo(
    () =>
      matches.some(({ handle }) => {
        const disallowedRoles = (handle as HandleCustomType)?.disallowedRoles;
        return user.userRole && disallowedRoles?.includes(user.userRole);
      }),
    [matches, user],
  );

  useEffect(() => {
    if (!dataExists) {
      navigate(loginPath, { state: { from: location } });
    }
  }, [dataExists, loginPath, navigate, location]);

  if (!tokenApplied || !user.userRole) {
    return <GlobalLoader open />;
  }

  if (permissionDeniedByRole) {
    navigate(routePaths.accessDenied, { replace: true });
  }

  return <ProtectedRoutesProvider>{outlet}</ProtectedRoutesProvider>;
};
