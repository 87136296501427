import { Checkbox, FormHelperText, InputLabel } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CheckMarkIcon,
  CloseIconWrapper,
  MenuItemStyled,
  MultipleSelectChip,
  MultipleSelectValue,
  NoData,
  SelectStyled,
  SelectWrapper,
} from './styles';
import { CustomSelectProps } from './types';
import { Checked, ChevronDownL, Close } from 'components/icons';

export const Select: FC<CustomSelectProps> = ({
  name,
  label,
  value,
  items,
  helperText,
  error,
  onChange,
  onItemClick,
  fullWidth,
  multiple,
  disabled,
  sx,
  className,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const isItemSelected = useCallback(
    (itemValue?: string) =>
      Array.isArray(value)
        ? !!value.find((v) => v === itemValue)
        : itemValue === value,
    [value],
  );

  const renderValue = useCallback(
    (itemValue: unknown) => {
      if (Array.isArray(itemValue)) {
        return (
          <MultipleSelectValue>
            {itemValue.map((v) => (
              <MultipleSelectChip
                label={
                  <>
                    {items.find((selectItem) => selectItem.value === v)?.label}
                    <CloseIconWrapper
                      onMouseDown={(e) => {
                        e.stopPropagation();
                        onItemClick?.(v);
                      }}
                    >
                      <Close />
                    </CloseIconWrapper>
                  </>
                }
                key={v}
                variant="outlined"
              />
            ))}
          </MultipleSelectValue>
        );
      }

      return items.find((item) => item.value === value)?.label;
    },
    [value, items, onItemClick],
  );

  return (
    <SelectWrapper
      disabled={disabled}
      sx={sx}
      className={className}
      fullWidth={fullWidth}
      error={error}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <SelectStyled
        multiple={multiple}
        IconComponent={ChevronDownL}
        renderValue={renderValue}
        value={value}
        fullWidth
        MenuProps={{
          variant: 'menu',
          autoFocus: false,
          sx: (theme) => ({
            '& .MuiMenu-paper': {
              marginTop: theme.spacing(1.5),
              borderRadius: '12px',
              boxShadow: `0px 5px 10px 0px rgba(30, 34, 40, 0.10), 0px 0px 1px 0px rgba(30, 34, 40, 0.08)`,
              '& .MuiMenu-list': {
                padding: 0,
              },
            },
          }),
        }}
        {...otherProps}
      >
        {!items.length && (
          <NoData variant="bodyM">
            {t('components.select.noDataPlaceholder')}
          </NoData>
        )}
        {items.map((item) => (
          <MenuItemStyled
            key={item.value}
            value={item.value}
            selected={isItemSelected(item.value)}
            onClick={() => onItemClick?.(item.value)}
          >
            {multiple ? (
              <>
                <Checkbox
                  checked={isItemSelected(item.value)}
                  size="medium"
                  disableRipple
                  checkedIcon={<Checked />}
                />
                {item.label}
              </>
            ) : (
              <>
                {item.label}
                {isItemSelected(item.value) ? <CheckMarkIcon /> : null}
              </>
            )}
          </MenuItemStyled>
        ))}
      </SelectStyled>
      {helperText ? (
        <FormHelperText error={!!error}>{helperText}</FormHelperText>
      ) : null}
    </SelectWrapper>
  );
};
