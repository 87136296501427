import { styled } from '@mui/material';

export const MobileBottomPanel = styled('div')(({ theme }) => ({
  position: 'fixed',
  left: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  padding: theme.spacing(2, 2, 3),
  backgroundColor: theme.palette.white.main,
  boxShadow: `0px -5px 10px 0px rgba(30, 34, 40, 0.1), 0px 0px 1px 0px rgba(30, 34, 40, 0.08)`,
}));
