import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const CircleInfo: FC<CommonSvgProps> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      stroke="#21252C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.5 7.99994C13.5 8.82837 12.8284 9.49994 12 9.49994C11.1716 9.49994 10.5 8.82837 10.5 7.99994C10.5 7.17151 11.1716 6.49994 12 6.49994C12.8284 6.49994 13.5 7.17151 13.5 7.99994Z"
      fill="#21252C"
    />
    <path
      d="M12 12V16"
      stroke="#21252C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
