import axios, { AxiosError } from 'axios';

export const isUnauthorizedError = (error: AxiosError) =>
  error.response?.status === 401;

export const isForbiddenError = (error: any): boolean => {
  if (axios.isAxiosError(error)) {
    return error.response?.status === 403;
  }

  return false;
};

export const isConnectionError = (error: AxiosError) =>
  error.response?.status === 503;

export const isNotFoundError = (error: any): boolean => {
  if (axios.isAxiosError(error)) {
    return error.response?.status === 404;
  }

  return false;
};
