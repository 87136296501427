import { Box, SxProps, Typography } from '@mui/material';
import { FC } from 'react';

import { WindTurbineParametersWrapper } from './styles';
import { WIDGETS } from '../../constants';
import { GrafanaIframe } from '../GrafanaIframe';
import { InfoTooltip } from 'components/common/InfoTooltip';

interface Props {
  name: string;
  widgetsInfoItems: { [key: string]: { title: string; tooltip: string } }[];
  widget: WIDGETS;
  sx?: SxProps;
}

export const WindTurbineParameters: FC<Props> = ({
  name,
  widgetsInfoItems,
  widget,
  sx,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={3} sx={sx}>
      <Typography variant="h4">{name}</Typography>
      <WindTurbineParametersWrapper>
        <Box display="flex" flexDirection="column" gap={0.375}>
          {Object.values(widgetsInfoItems[0]).map((item) => (
            <Box display="flex" alignItems="center" gap={1.5} height="71px">
              <Typography variant="h5">{item.title}</Typography>
              <InfoTooltip title={item.tooltip} />
            </Box>
          ))}
        </Box>
        <Box overflow="hidden" height={71}>
          <GrafanaIframe panelId={widget} />
        </Box>
      </WindTurbineParametersWrapper>
    </Box>
  );
};
