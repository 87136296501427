import {
  WindTurbineDtoOperationalStatusEnum,
  WindTurbineDtoStatusEnum,
} from 'openapi-api/admin-service';
import { FilterValues, TranslationFunction } from 'types/general';

export const fieldsToSend = [
  'search',
  'ratedPower',
  'status',
  'operationalStatus',
  'accelerationStatus',
  'connected',
  'page',
  'size',
  'sort',
];

export const clickDisabledFields = ['linkToDetailedPage'];

export const turbinesFilterValues = (t: TranslationFunction): FilterValues => ({
  operationalStatus: {
    label: t('filters.workingStatus'),
    values: [
      {
        label: t('filters.active'),
        value: WindTurbineDtoOperationalStatusEnum.ACTIVATED,
      },
      {
        label: t('filters.inactive'),
        value: WindTurbineDtoOperationalStatusEnum.DEACTIVATED,
      },
    ],
  },
  status: {
    label: t('filters.assetStatus'),
    values: [
      {
        label: t('filters.ready'),
        value: WindTurbineDtoStatusEnum.READY,
      },
      {
        label: t('filters.created'),
        value: WindTurbineDtoStatusEnum.CREATED,
      },
      {
        label: t('filters.onboarding'),
        value: WindTurbineDtoStatusEnum.ONBOARDING,
      },
      {
        label: t('filters.offline'),
        value: WindTurbineDtoStatusEnum.OFFLINE,
      },
      {
        label: t('filters.error'),
        value: WindTurbineDtoStatusEnum.ERROR,
      },
    ],
  },
});

export const sorting = {
  name: 'name',
  generatedEnergy: 'totalElectricity',
  windSpeed: 'windSpeed',
  activePower: 'activePower',
};
