import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { WIDGETS } from '../../constants';
import { MechanicalParameter } from '../MechanicalParameter';
import { WindTurbineChart } from '../WindTurbineChart';
import { WindTurbineParameters } from '../WindTurbineParameters';
import { WindTurbinePanelsProvider } from 'contexts/WindTurbinePanels';

export const WindTurbineGeneralInfoContent = () => {
  const { t } = useTranslation();

  return (
    <WindTurbinePanelsProvider dashboardType="GENERAL">
      <Box display="flex" flexDirection="column" gap={{ mobile: 4, tablet: 5 }}>
        <Grid
          container
          columnSpacing={{ tablet: 1, desktop: 3 }}
          rowSpacing={{ mobile: 1.5 }}
        >
          <Grid item mobile={12} tablet={4}>
            <MechanicalParameter
              title={t('pages.turbine.mechanicalParameter')}
              name={t('pages.turbine.windSpeed')}
              widget={WIDGETS.WIND_SPEED}
            />
          </Grid>
          <Grid item mobile={12} tablet={4}>
            <MechanicalParameter
              title={t('pages.turbine.mechanicalParameter')}
              name={t('pages.turbine.generatorSpeed')}
              widget={WIDGETS.GENERATOR_SPEED}
            />
          </Grid>
          <Grid item mobile={12} tablet={4}>
            <MechanicalParameter
              title={t('pages.turbine.networkParameter')}
              name={t('pages.turbine.activePower')}
              widget={WIDGETS.ACTIVE_POWER}
            />
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={{ tablet: 2.5, desktop: 4 }}
          rowSpacing={{ mobile: 2.5 }}
        >
          <Grid item mobile={12} tablet={6}>
            <WindTurbineChart
              title={t('pages.turbine.windSpeed')}
              tooltipText={t('pages.turbine.windSpeedTooltip')}
              panelId={WIDGETS.WIND_SPEED_CHART}
            />
          </Grid>
          <Grid item mobile={12} tablet={6}>
            <WindTurbineChart
              title={t('pages.turbine.activePower')}
              tooltipText={t('pages.turbine.activePowerTooltip')}
              panelId={WIDGETS.ACTIVE_POWER_CHART}
            />
          </Grid>
        </Grid>
        <WindTurbineParameters
          sx={{ mt: { mobile: 5, tablet: 2.5, desktop: 4 } }}
          name={t('pages.turbine.summaryParameters.title')}
          widgetsInfoItems={t('pages.turbine.summaryParameters.data', {
            returnObjects: true,
          })}
          widget={WIDGETS.SUMMARY_PARAMETERS_VERTICAL}
        />
      </Box>
    </WindTurbinePanelsProvider>
  );
};
