import { Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { Trans } from 'react-i18next';

import { UserIdLink } from 'components/shared/HistoryLog/styles';
import routePaths from 'constants/routePaths';
import {
  UserChangedWindTurbineNameDto,
  UserUserRoleEnum,
} from 'openapi-api/admin-service';

export const WindTurbineDetailsChangedTemplate: FC<
  UserChangedWindTurbineNameDto
> = ({
  subjectUser,
  previousAssetNameForUser,
  currentAssetNameForUser,
  eventDateTime,
}) => {
  const userLink = useMemo(() => {
    if (!subjectUser?.username && !subjectUser?.userId) {
      return <Typography variant="bodyMStrong">{'undefined'}</Typography>;
    }

    if (subjectUser.userRole === UserUserRoleEnum.USER_PORTAL_SUPER_ADMIN) {
      return (
        <Typography variant="bodyMStrong">
          {subjectUser.username || subjectUser.userId}
        </Typography>
      );
    }

    return (
      <UserIdLink to={routePaths.users.profile(subjectUser?.id!).historyLog}>
        {subjectUser.username}
      </UserIdLink>
    );
  }, [subjectUser]);

  return (
    <>
      <Trans
        i18nKey="components.historyLog.templates.asset.activities.windTurbineDetailsChanged"
        values={{ eventDateTime }}
        components={{
          userLink,
        }}
      />
      <br />
      <Trans
        i18nKey="components.historyLog.templates.asset.activities.windTurbineNameChanged"
        values={{
          previous: previousAssetNameForUser,
          current: currentAssetNameForUser,
        }}
        components={{ bold: <strong /> }}
      />
    </>
  );
};
