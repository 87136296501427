import { Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { UserIdLink } from 'components/shared/HistoryLog/styles';
import routePaths from 'constants/routePaths';
import {
  UserChangedAssetOperationalStatusDto,
  UserChangedAssetOperationalStatusDtoAllOfOperationalStatusEnum,
  UserUserRoleEnum,
} from 'openapi-api/admin-service';

export const OperationalStatusChangedTemplate: FC<
  UserChangedAssetOperationalStatusDto
> = ({ assetNameForUser, operationalStatus, eventDateTime, subjectUser }) => {
  const { t } = useTranslation();

  const operationalStatusText = useMemo(
    () =>
      operationalStatus ===
      UserChangedAssetOperationalStatusDtoAllOfOperationalStatusEnum.ACTIVATED
        ? t('pages.turbine.on')
        : t('pages.turbine.off'),
    [operationalStatus, t],
  );

  const userLink = useMemo(() => {
    if (!subjectUser?.username && !subjectUser?.userId) {
      return <Typography variant="bodyMStrong">{'undefined'}</Typography>;
    }

    if (subjectUser.userRole === UserUserRoleEnum.USER_PORTAL_SUPER_ADMIN) {
      return (
        <Typography variant="bodyMStrong">
          {subjectUser.username || subjectUser.userId}
        </Typography>
      );
    }

    return (
      <UserIdLink to={routePaths.users.profile(subjectUser?.id!).historyLog}>
        {subjectUser.username}
      </UserIdLink>
    );
  }, [subjectUser]);

  return (
    <Trans
      i18nKey="components.historyLog.templates.asset.activities.operationalStatusChanged"
      values={{
        assetName: assetNameForUser,
        status: operationalStatusText.toUpperCase(),
        eventDateTime,
      }}
      components={{
        userLink,
        bold: <strong />,
      }}
    />
  );
};
