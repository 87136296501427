import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ICustomerUserContext,
  CustomerUserContext,
} from './CustomerUserContext';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import { isNotFoundError } from 'contexts/auth/helpers';
import { UserDetailsDtoStatusEnum } from 'openapi-api/admin-service';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

export const CustomerUserProvider: FC<PropsWithChildren> = ({ children }) => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { customerUserControllerApi } = useApi();
  const { t } = useTranslation();

  const getCustomerUserDetails = useCallback(async () => {
    if (!userId) return;

    try {
      return (
        await customerUserControllerApi.getCustomerUserDetailsById({
          id: Number(userId),
        })
      ).data;
    } catch (error) {
      if (isNotFoundError(error)) {
        navigate(routePaths.notFound);
      }
    }
  }, [userId, customerUserControllerApi, navigate]);

  const {
    resource: customerUser,
    fetch,
    isLoading,
  } = useAsyncResource({
    fetchResource: getCustomerUserDetails,
  });

  const username = useMemo(() => {
    if (customerUser?.status === UserDetailsDtoStatusEnum.DELETED) {
      return t('anonymousUser');
    }

    return customerUser?.username || 'undefined';
  }, [customerUser, t]);

  const contextValue = useMemo(
    (): ICustomerUserContext => ({
      customerUser: {
        ...customerUser,
        username,
      },
      isLoading,
      fetch,
    }),
    [customerUser, username, fetch, isLoading],
  );

  return (
    <CustomerUserContext.Provider value={contextValue}>
      {children}
    </CustomerUserContext.Provider>
  );
};
