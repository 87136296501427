import dayjs from 'dayjs';
import { FC, useMemo } from 'react';

import { HistoryLogItem } from 'components/shared/HistoryLog';
import {
  OperationalStatusChangedTemplate,
  WindTurbineDetailsChangedTemplate,
} from 'components/shared/HistoryLog/templates/user';
import {
  HistoryLogDto,
  HistoryLogDtoNameEnum,
  UserChangedAssetOperationalStatusDto,
  UserChangedWindTurbineNameDto,
  UserDetailsDtoUserRoleEnum,
} from 'openapi-api/admin-service';

interface Props {
  data: HistoryLogDto;
  currentUserRole?: UserDetailsDtoUserRoleEnum;
}

export const UserHistoryLogItem: FC<Props> = ({
  data: { name, type, createDatetime, details, locationId },
}) => {
  const additionalProps = useMemo((): {
    eventDateTime: string;
    locationId?: number;
  } => {
    return {
      locationId,
      eventDateTime: dayjs(details?.eventDateTime).format(
        'DD/MM/YYYY HH:mm:ss',
      ),
    };
  }, [locationId, details?.eventDateTime]);

  const content = useMemo(() => {
    switch (name) {
      case HistoryLogDtoNameEnum.USER_CHANGED_WT_OPERATIONAL_STATUS:
        return (
          <OperationalStatusChangedTemplate
            {...(details as UserChangedAssetOperationalStatusDto)}
            {...additionalProps}
          />
        );
      case HistoryLogDtoNameEnum.USER_CHANGED_WT_NAME:
        return (
          <WindTurbineDetailsChangedTemplate
            {...(details as UserChangedWindTurbineNameDto)}
            {...additionalProps}
          />
        );
      default:
        return 'event name is undefined';
    }
  }, [name, details, additionalProps]);

  return (
    <HistoryLogItem type={type} datetime={createDatetime}>
      {content}
    </HistoryLogItem>
  );
};
